import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { useEnrollmentContext } from 'contexts/EnrollmentContext/EnrollmentContext';
import Enrollment from 'components/templates/Enrollment/Enrollment';
import ContentHeaderWrapper from 'components/atoms/ContentHeaderWrapper/ContentHeaderWrapper';
import ContentBodyWrapper from 'components/atoms/ContentBodyWrapper/ContentBodyWrapper';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import theme from 'theme';
import Badge from '@mui/material/Badge';
import CheckIcon from '@mui/icons-material/Check';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import TimeIcon from 'assets/time-icon.svg';
import CalendarIcon from 'assets/calendar-icon.svg';
import HybridClassIcon from 'assets/hybrid-class-type.svg';
import OnlineClassIcon from 'assets/online-class-type.svg';
import LocationClassIcon from 'assets/location-class-type.svg';
import Box from '@mui/material/Box';
import { LessonFormat } from 'utils/signupDetails';
import formatAvailabilityDays from 'utils/format/formatAvailabilityDays';
import AddNewStudentButton from 'components/molecules/AddNewStudentButton/AddNewStudentButton';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Button from '@mui/material/Button';
import EnrollmentHeader from 'pages/Enrollment/components/EnrollmentHeader/EnrollmentHeader';
import { PaymentType, SchedulingPreference, SchedulingPriority, SiblingSchedulePreference } from 'generated/graphql';
import { getSecondaryColor, STUDENT_COLORS } from 'utils/colors/studentColors';
import hexToRGB from 'utils/colors/hexToRGB';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

type SelectedProp = {
  selected: boolean;
};

const CardShadow = styled(Card)`
  margin: 1rem;
  ${({ selected }: SelectedProp) => `outline: ${selected ? theme.colors.cobalt : 'rgba(0, 0, 0, 0)'} solid 0.25rem;`}
  transition: outline 0.1s ease-out;

  border-radius: 0.625rem;
  background: ${theme.colors.cloudy};
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.3);
`;

const CardClasses = styled(CardShadow)`
  min-width: 24.5rem;
  max-width: 100%;
`;

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    position: 'absolute',
    top: '1.125rem',
    right: '1.125rem',
    height: '1.75rem',
    width: '1.75rem',
    borderRadius: '1rem',
    backgroundColor: theme.colors.cobalt,
  },
}));

const StudentEnrollmentContainer = styled('div')`
  margin: 1.5rem 0 1.75rem;
`;

const StyledFormControl = styled(FormControl)`
  margin: 1rem;
  min-width: 12rem;
`;

const StyledRadio = styled(Radio)`
  color: ${theme.colors.enrollText};

  &.Mui-checked {
    color: ${theme.colors.cobalt};
  }
`;

const IconTextGrid = styled(Grid)`
  padding: 0.625rem 1rem;
`;

const IconTextBox = styled(Box)`
  margin: 0.75rem 0;
`;

const EditButton = styled(Button)`
  color: ${theme.colors.greySilver};
  font-size: 0.625rem;
  margin-right: 0.75rem;
  text-transform: none;
`;

const StyledTypography = styled(Typography)`
  width: 100%;
  display: flex;
  justify-content: start;
  align-self: center;
  margin-left: 0.313rem;
`;

const Summary = () => {
  const {
    schedule,
    selectedStudents,
    studentEnrollments,
    siblingConsideration,
    schedulingPriority,
    phoneNumber,
    setSelectedStudents,
    setSiblingConsideration,
    setSchedulingPriority,
    setStudentSchedulingConsideration,
    setComments,
    setPaymentPreference,
    setPhoneNumber,
  } = useEnrollmentContext();
  const { scheduleId } = useParams();
  const navigate = useNavigate();
  // Prevent the default context value from being selected initially
  const [payPreference, handlePaymentPreference] = useState<string | null>(null);
  const handlePaymentPreferenceChange = (value: string) => {
    handlePaymentPreference(value);
    const paymentType =
      value === PaymentType.PayInFull
        ? PaymentType.PayInFull
        : value === PaymentType.Monthly
        ? PaymentType.Monthly
        : value === PaymentType.Zelle
        ? PaymentType.Zelle
        : PaymentType.PayByCheck;
    setPaymentPreference(paymentType); // Sync with context
  };

  useEffect(() => {
    if (!selectedStudents?.length || !studentEnrollments?.length) {
      navigate(`/enroll/${scheduleId}`);
    }
    if (!studentEnrollments.some((x) => x.selectedLessons.length || x.selectedClasses.length)) {
      if (selectedStudents?.length) {
        navigate(`/enroll/${scheduleId}/student/${selectedStudents[0].id}/lesson-selection`);
      } else {
        navigate(`/enroll/${scheduleId}`);
      }
    }
  }, [navigate, scheduleId, selectedStudents, selectedStudents?.length, studentEnrollments]);

  const handleEditClick = (scheduleId: number | string, studentId: number) => {
    const filteredStudents = selectedStudents?.filter((s) => s.id !== studentId);
    const lastStudent = selectedStudents?.find((s) => s.id === studentId);
    filteredStudents && lastStudent && setSelectedStudents([...filteredStudents, lastStudent]);
    navigate(`/enroll/${scheduleId}/student/${studentId}/lesson-selection`);
  };

  let lastStudentId = -1;
  if (selectedStudents && selectedStudents?.length > 0) {
    lastStudentId = selectedStudents[selectedStudents.length - 1].id;
  }

  return (
    <Enrollment
      back={{ path: `/enroll/${scheduleId}/student/${lastStudentId}/sign-up-details`, description: 'Sign-Up Details' }}
      next={{
        path: `/enroll/${scheduleId}/policies`,
        description: 'Policies',
        disabled: !phoneNumber || !payPreference,
      }}
    >
      <EnrollmentHeader />
      <ContentHeaderWrapper>
        <Typography variant={'h1'} fontSize={'1.5rem'}>
          Registration Summary
        </Typography>
      </ContentHeaderWrapper>
      <ContentBodyWrapper>
        <SectionHeader variant={'h4'}>{schedule?.name} Registration</SectionHeader>
        <StudentEnrollmentContainer>
          {studentEnrollments?.map((se) => {
            const selectedLessonsByStudent = se.selectedLessons;
            const selectedTeachersByStudent = se.selectedTeachers;
            const selectedClassesByStudent = se.selectedClasses;
            const selectedLessonsSignupDetails = se.selectedLessonsSignupDetails;
            const studentColor = se?.student?.color || STUDENT_COLORS.purple.primary;
            const studentColorSecondary = hexToRGB(getSecondaryColor(studentColor), '0.72');

            return (
              <div key={`selectedLessonsOfStudent-${se.student.id}`}>
                <Typography variant={'h1'} fontSize={'1.5rem'}>
                  {se.student.user?.firstName}
                </Typography>

                {selectedLessonsByStudent && selectedLessonsByStudent.length > 1 && (
                  <StyledFormControl>
                    <Typography id={`scheduling-consideration-label-${se.student.id}`}>
                      Scheduling preference for these lessons?
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby={`scheduling-consideration-label-${se.student.id}`}
                      name={`scheduling-consideration-${se.student.id}`}
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                      onChange={(e) => {
                        const value = e.target.value as SchedulingPreference;
                        setStudentSchedulingConsideration(se.student.id, value);
                        return e;
                      }}
                      value={se.schedulingConsideration || SchedulingPreference.NoPreference}
                    >
                      <FormControlLabel
                        value={SchedulingPreference.ScheduleTogether}
                        control={<StyledRadio />}
                        label={'Scheduled back-to-back, when possible'}
                      />
                      <FormControlLabel
                        value={SchedulingPreference.ScheduleApart}
                        control={<StyledRadio />}
                        label={'Scheduled on separate days, when possible'}
                      />
                      <FormControlLabel
                        value={SchedulingPreference.NoPreference}
                        control={<StyledRadio />}
                        label={'No preference'}
                      />
                    </RadioGroup>
                  </StyledFormControl>
                )}

                {selectedLessonsByStudent?.map((lesson) => {
                  const selectedTeacher = selectedTeachersByStudent.find((t) => t.skillId === lesson.id);
                  const signUpDetails = selectedLessonsSignupDetails?.find((t) => t.id === lesson.id);
                  const teacher = selectedTeacher?.teacher;
                  const teacherName = teacher?.user?.firstName;
                  const teacherLastName = teacher?.user?.lastName;
                  const locationIcon =
                    signUpDetails?.lessonFormat === LessonFormat.Hybrid
                      ? HybridClassIcon
                      : signUpDetails?.lessonFormat === LessonFormat.Virtual
                      ? OnlineClassIcon
                      : LocationClassIcon;

                  const availabilities =
                    teacher?.scheduleAvailability?.find(
                      ({ scheduleId: _scheduleId }) => _scheduleId && _scheduleId === Number(scheduleId)
                    ) ?? teacher?.defaultAvailability;

                  return (
                    <Box key={`student-id-${se?.student.id}`}>
                      <StyledBadge badgeContent={<CheckIcon />} color='primary' invisible={true}>
                        <CardClasses>
                          <CardActionArea>
                            <CardContent sx={{ padding: 0 }}>
                              <Grid container>
                                <Grid item xs={1}>
                                  <Box
                                    sx={{
                                      width: '1.438rem',
                                      height: '100%',
                                      background: `linear-gradient(90deg, ${studentColor} 0%, ${studentColorSecondary} 100%)`,
                                    }}
                                  />
                                </Grid>
                                <IconTextGrid item xs={7}>
                                  <Typography
                                    sx={{
                                      fontSize: '0.75rem',
                                      color: theme.colors.enrollText,
                                      fontWeight: 600,
                                      textTransform: 'uppercase',
                                    }}
                                  >
                                    {lesson.skillName}
                                  </Typography>
                                  <Typography variant='h3' sx={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
                                    {teacherName} {teacherLastName}
                                  </Typography>
                                  <IconTextBox display='flex' justifyContent='flex-start'>
                                    <img
                                      src={TimeIcon}
                                      alt={`${signUpDetails?.sessionLength} min`}
                                      style={{ width: '1rem' }}
                                    />
                                    <Typography sx={{ fontSize: '1rem', marginLeft: '0.313rem' }}>
                                      Length: {`${signUpDetails?.sessionLength} min`}
                                    </Typography>
                                  </IconTextBox>
                                </IconTextGrid>
                                <Grid item xs={4} sx={{ padding: '0.625rem 1rem' }}>
                                  {teacher?.featureImage && (
                                    <CardMedia
                                      component='img'
                                      sx={{ width: 86, borderRadius: '0.563rem', maxHeight: '11.875rem' }}
                                      image={teacher.featureImage || ''}
                                      alt={`Teacher photo ${teacherName}`}
                                    />
                                  )}
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={1}>
                                  <Box
                                    sx={{
                                      width: '1.438rem',
                                      height: '100%',
                                      background: `linear-gradient(90deg, ${studentColor} 0%, ${studentColorSecondary} 100%)`,
                                    }}
                                  />
                                </Grid>
                                <IconTextGrid item xs={11}>
                                  <IconTextBox display='flex' justifyContent='flex-start' sx={{ marginTop: '-1rem' }}>
                                    <img
                                      src={locationIcon}
                                      alt={signUpDetails?.lessonFormat}
                                      style={{ width: '1rem' }}
                                    />
                                    <StyledTypography>Location: {signUpDetails?.lessonFormat}</StyledTypography>
                                  </IconTextBox>
                                  <IconTextBox display='flex' justifyContent='flex-start'>
                                    <img
                                      src={CalendarIcon}
                                      alt={`${signUpDetails?.sessionLength} min`}
                                      style={{ width: '1rem' }}
                                    />
                                    <StyledTypography>
                                      Availability:{' '}
                                      {availabilities && (
                                        <Typography fontSize='1rem' marginLeft={'0.313rem'}>
                                          {formatAvailabilityDays(availabilities, {
                                            skillIds: [lesson.id],
                                          })}
                                        </Typography>
                                      )}
                                    </StyledTypography>
                                  </IconTextBox>
                                </IconTextGrid>
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </CardClasses>
                      </StyledBadge>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <EditButton onClick={() => handleEditClick(scheduleId || '', se.student.id)}>Edit</EditButton>
                      </Box>
                    </Box>
                  );
                })}
                {selectedClassesByStudent?.map((cl) => {
                  const teacherName = cl.teacherDisplayText;
                  const location = cl.subtitle;

                  return (
                    <Box key={`student-id-${cl?.id}`}>
                      <StyledBadge key={cl?.id} badgeContent={<CheckIcon />} color='primary' invisible={true}>
                        <CardClasses>
                          <CardActionArea>
                            <CardContent sx={{ padding: 0 }}>
                              <Grid container>
                                <Grid item xs={1}>
                                  <Box
                                    sx={{
                                      width: '1.438rem',
                                      height: '100%',
                                      background: `linear-gradient(90deg, ${studentColor} 0%, ${studentColorSecondary} 100%)`,
                                    }}
                                  />
                                </Grid>
                                <IconTextGrid item xs={7}>
                                  <Typography
                                    sx={{
                                      fontSize: '0.75rem',
                                      color: theme.colors.enrollText,
                                      fontWeight: 600,
                                      textTransform: 'uppercase',
                                    }}
                                  >
                                    {cl.name}
                                  </Typography>
                                  {teacherName && (
                                    <Typography variant='h3' sx={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
                                      {teacherName}
                                    </Typography>
                                  )}
                                  <IconTextBox display='flex' justifyContent='flex-start'>
                                    <img src={TimeIcon} alt={cl?.appointmentType?.name} style={{ width: '1rem' }} />
                                    <Typography sx={{ fontSize: '1rem', marginLeft: '0.313rem' }}>
                                      Length: {cl?.appointmentType?.duration} min
                                    </Typography>
                                  </IconTextBox>
                                </IconTextGrid>
                                <Grid item xs={4} sx={{ padding: '0.625rem 1rem' }}>
                                  {cl?.packageImageUrl && (
                                    <CardMedia
                                      component='img'
                                      sx={{ width: 86, borderRadius: '0.563rem', maxHeight: '11.875rem' }}
                                      image={cl.packageImageUrl || ''}
                                      alt={`Teacher photo ${teacherName}`}
                                    />
                                  )}
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={1}>
                                  <Box
                                    sx={{
                                      width: '1.438rem',
                                      height: '100%',
                                      background: `linear-gradient(90deg, ${studentColor} 0%, ${studentColorSecondary} 100%)`,
                                    }}
                                  />
                                </Grid>
                                {location && (
                                  <IconTextGrid item xs={11}>
                                    <IconTextBox display='flex' justifyContent='flex-start' sx={{ marginTop: '-1rem' }}>
                                      <img src={CalendarIcon} alt='Calendar icon' style={{ width: '1rem' }} />
                                      <Typography sx={{ fontSize: '1rem', marginLeft: '0.313rem' }}>
                                        {location}
                                      </Typography>
                                    </IconTextBox>
                                  </IconTextGrid>
                                )}
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </CardClasses>
                      </StyledBadge>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <EditButton onClick={() => handleEditClick(scheduleId || '', se.student.id)}>Edit</EditButton>
                      </Box>
                    </Box>
                  );
                })}
              </div>
            );
          })}
        </StudentEnrollmentContainer>
        <AddNewStudentButton scheduleId={scheduleId || ''} />
        <StyledFormControl>
          <Typography id='schedule-sibling-together-label'>Schedule with siblings/family?</Typography>
          <RadioGroup
            row
            aria-labelledby='schedule-sibling-together-label'
            name='schedule-sibling'
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            onChange={(e) => {
              const value =
                e.target.value === SiblingSchedulePreference.ScheduleTogether
                  ? SiblingSchedulePreference.ScheduleTogether
                  : SiblingSchedulePreference.NoPreference;
              setSiblingConsideration(value);

              // If setting to Yes and scheduling priority is still no preference, then default it to preferred
              // instructor.
              if (
                value === SiblingSchedulePreference.ScheduleTogether &&
                schedulingPriority === SchedulingPriority.NoPreference
              ) {
                setSchedulingPriority(SchedulingPriority.WithPreferredInstructor);
              }
              return e;
            }}
            value={siblingConsideration || SiblingSchedulePreference.NoPreference}
            defaultChecked={true}
            defaultValue={SiblingSchedulePreference.NoPreference}
          >
            <FormControlLabel value={SiblingSchedulePreference.NoPreference} control={<StyledRadio />} label={'No'} />
            <FormControlLabel
              value={SiblingSchedulePreference.ScheduleTogether}
              control={<StyledRadio />}
              label={'Yes'}
            />
          </RadioGroup>
        </StyledFormControl>
        {siblingConsideration === SiblingSchedulePreference.ScheduleTogether && (
          <StyledFormControl>
            <Typography id='schedule-priority-label'>What&apos;s the higher priority?</Typography>
            <RadioGroup
              row
              aria-labelledby='schedule-priority-label'
              name='schedulePriority'
              sx={{ display: 'flex', justifyContent: 'space-between' }}
              onChange={(e) => {
                const value =
                  e.target.value === SchedulingPriority.WithFamily
                    ? SchedulingPriority.WithFamily
                    : SchedulingPriority.WithPreferredInstructor;
                setSchedulingPriority(value);
                return e;
              }}
              value={schedulingPriority || SchedulingPriority.WithPreferredInstructor}
            >
              <FormControlLabel
                value={SchedulingPriority.WithFamily}
                control={<StyledRadio />}
                label='Scheduling with siblings/family'
              />
              <FormControlLabel
                value={SchedulingPriority.WithPreferredInstructor}
                control={<StyledRadio />}
                label={'Staying with the indicated teacher'}
              />
            </RadioGroup>
          </StyledFormControl>
        )}
        <Box sx={{ margin: '2rem 1rem' }}>
          <Typography id='payment-preference-label'>Payment Preference</Typography>
          <FormControl component='fieldset'>
            <RadioGroup
              row
              aria-labelledby='payment-preference-label'
              name='paymentPreference'
              sx={{ display: 'flex', justifyContent: 'space-between' }}
              value={payPreference}
              onChange={(event) => {
                const val = event?.target.value;
                handlePaymentPreferenceChange(val);
              }}
              aria-required={true}
            >
              <FormControlLabel
                value={PaymentType.PayInFull}
                control={<StyledRadio />}
                label='Pay-in-full with card (3% discount)'
              />
              <FormControlLabel
                value={PaymentType.Monthly}
                control={<StyledRadio />}
                label='Monthly payments with card'
              />
              <FormControlLabel
                value={PaymentType.Zelle}
                control={<StyledRadio />}
                label='I prefer to pay with Zelle (pay-in-full only)'
              />
              <FormControlLabel
                value={PaymentType.PayByCheck}
                control={<StyledRadio />}
                label='Pay by Check (pay-in-full only)'
              />
            </RadioGroup>
          </FormControl>
          {!payPreference && <FormHelperText error={true}>Payment Preference is required</FormHelperText>}
        </Box>
        <Box sx={{ margin: '2rem 1rem' }}>
          <TextField
            fullWidth
            id='phoneNumber'
            name='phoneNumber'
            label='Phone Number (required)'
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            variant='standard'
            required
          />
        </Box>
        <Box sx={{ margin: '2rem 1rem' }}>
          <TextareaAutosize
            placeholder={
              'Additional Comments? (Including but not limited to student goals, experience, learning styles, IEPs or 504 plans, or anything applicable to lessons or scheduling)'
            }
            style={{ width: '24.5rem', height: '8rem', resize: 'none' }}
            onChange={(event) => {
              setComments(event.target.value);
            }}
          />
        </Box>
      </ContentBodyWrapper>
    </Enrollment>
  );
};

export default Summary;
